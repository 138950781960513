exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-aquino-tsx": () => import("./../../../src/pages/aquino.tsx" /* webpackChunkName: "component---src-pages-aquino-tsx" */),
  "component---src-pages-dinerama-tsx": () => import("./../../../src/pages/dinerama.tsx" /* webpackChunkName: "component---src-pages-dinerama-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nota-fiscal-tsx": () => import("./../../../src/pages/nota-fiscal.tsx" /* webpackChunkName: "component---src-pages-nota-fiscal-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-pages-whatsapp-tsx": () => import("./../../../src/pages/whatsapp.tsx" /* webpackChunkName: "component---src-pages-whatsapp-tsx" */)
}

